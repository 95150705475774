import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { debounceTime, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { EntitiesService } from '../../../@core/akita-stores/entity-stores/entities/entities.service';

interface CheckItem {
  id: number;
  name: string;
}

@Component({
  selector: 'select-service-center-dlg',
  templateUrl: './select-service-center-dlg.component.html',
  styleUrls: ['./select-service-center-dlg.component.scss']
})
export class SelectServiceCenterDlgComponent implements OnInit {

  @Input()
  selectedEntities: any = [];

  @Input()
  entities: any = [];

  @Input()
  editId: number;

  centreSelectForm: FormGroup;


  sourceList: CheckItem[] = [];
  targetList: CheckItem[] = [];

  sourceSelectedList: CheckItem[] = [];
  targetSelectedList: CheckItem[] = [];

  isSourceCheckAll = false;
  isTargetCheckAll = false;

  sourceSearchValue: string = '';
  targetSearchValue: string = '';

  get sourceListSearched() {
    const searchValue = this.sourceSearchValue.trim();
    return this.sourceList.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));
  }

  get sourceSelectedListSearched() {
    const searchValue = this.sourceSearchValue.trim();
    return this.sourceSelectedList.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));
  }

  get targetListSearched() {
    const searchValue = this.targetSearchValue.trim();
    return this.targetList.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));
  }

  get targetSelectedListSearched() {
    const searchValue = this.targetSearchValue.trim();
    return this.targetSelectedList.filter(v => v.name.toLowerCase().includes(searchValue.toLowerCase()));
  }

  get companyList() {
    if (!this.editId) {
      // remove selected entities from the entities list
      return this.entities.filter(v => !this.selectedEntities.find(e => e.id === v.id));
    }
    return this.entities;
  }

  constructor(
    private dialogRef: NbDialogRef<SelectServiceCenterDlgComponent>,
  ) {
    this.centreSelectForm = new FormGroup({
      company: new FormControl('', [Validators.required]),
      centre: new FormControl('', [Validators.required]),
      sourceSearchValue: new FormControl('', []),
      targetSearchValue: new FormControl('', []),
    });
  }

  ngOnInit() {
    this.centreSelectForm.get('sourceSearchValue').valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.sourceSearchValue = value;
      this.checkAllStatus(true);
    });
    this.centreSelectForm.get('targetSearchValue').valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.targetSearchValue = value;
      this.checkAllStatus(false);
    });

    this.centreSelectForm.get('company').valueChanges.subscribe((value) => {
      if (value) {
        this.isTargetCheckAll = false;
        this.isSourceCheckAll = false;
        this.sourceSelectedList = [];
        this.targetSelectedList = [];
        this.targetList = [];

        const sourceList = this.entities.find(v => v.id === value).childs || [];
        if (this.editId) {
          this.targetList = this.selectedEntities.find(v => v.id === value).childs || [];
          this.sourceList = sourceList.filter(v => !this.targetList.find(e => e.id === v.id));
        } else {
          this.sourceList = sourceList;
        }
      }
    });

    if (this.editId) {
      this.centreSelectForm.get('company').patchValue(this.editId);
      this.centreSelectForm.get('company').disable();
    } else if (this.companyList.length > 0) {
      this.centreSelectForm.get('company').patchValue(this.companyList[0].id);
    }
  }

  onSubmit() {
    const company = this.companyList.find(v => v.id === this.centreSelectForm.get('company').value);
    const centres = this.targetList;

    this.dialogRef.close({
      ...company,
      childs: centres
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  handleSearch(e, isSourceList: boolean) {
    e.preventDefault();
    e.stopPropagation();
    if (isSourceList) {
      this.sourceSearchValue = e.target.value;
    } else {
      this.sourceSearchValue = e.target.value;
    }
  }

  handleTransfer(e, isPush: boolean) {
    e.preventDefault();
    e.stopPropagation();
    if (isPush) {
      this.targetList = this.targetList.concat(this.sourceSelectedList);
      this.sourceList = this.sourceList.filter(item => !this.sourceSelectedList.find(selectedItem => selectedItem.id === item.id));
      this.sourceSelectedList = [];
      this.isTargetCheckAll = false;
      this.isSourceCheckAll = false;
    } else {
      this.sourceList = this.sourceList.concat(this.targetSelectedList);
      this.targetList = this.targetList.filter(item => !this.targetSelectedList.find(selectedItem => selectedItem.id === item.id));
      this.targetSelectedList = [];
      this.isTargetCheckAll = false;
      this.isSourceCheckAll = false;
    }
  }

  handleSelectTransferList(e, item: CheckItem, isSourceList: boolean, selectAll = false) {
    e.preventDefault();
    e.stopPropagation();
    if (selectAll) {
      if (isSourceList) {
        this.isSourceCheckAll = !this.isSourceCheckAll;
        if (this.isSourceCheckAll) {
          this.sourceSelectedList = _.cloneDeep(this.sourceListSearched);
        } else {
          this.sourceSelectedList = [];
        }
      } else {
        this.isTargetCheckAll = !this.isTargetCheckAll;
        if (this.isTargetCheckAll) {
          this.targetSelectedList = _.cloneDeep(this.targetListSearched);
        } else {
          this.targetSelectedList = [];
        }
      }
      return;
    }
    if (isSourceList) {
      this.checkExecute(item, this.sourceSelectedList);
    } else {
      this.checkExecute(item, this.targetSelectedList);
    }

    this.checkAllStatus(isSourceList);
  }

  checkAllStatus(isSourceList = true) {
    if (isSourceList) {
      this.isSourceCheckAll = this.sourceSelectedList.length === this.sourceList.length && this.sourceSelectedList.length > 0;
    } else {
      this.isTargetCheckAll = this.targetSelectedList.length === this.targetList.length && this.targetSelectedList.length > 0;
    }
  }

  private checkExecute(item: CheckItem, checkList: CheckItem[]) {
    const index = checkList.findIndex(v => v.id === item.id);
    if (index > -1) {
      checkList.splice(index, 1);
    } else {
      checkList.push(item);
    }
  }

  isSelectCheck(item: CheckItem, selectList) {
    return selectList.find(v => v.id === item.id);
  }

  preventKeyTrigger(keyEvent: KeyboardEvent) {
    if (keyEvent.code === "Enter") {
      keyEvent.preventDefault();
    }
  }
}
