import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { API_PATH } from './../../constants/api-urls';
import { share, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BookingDetails, BookingDetailsSelectedStore } from './../akita-stores/stores/booking-details-selected/booking-details-selected.store';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
import { ConfigStore, BookingEnum } from '../akita-stores/stores/config/config.store';
import { Share } from '@ngspot/rxjs/decorators';
import { BookmarkIconService } from '../icons/bookmark.icon';
import * as _ from 'lodash';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { STATUS_BEFORE_CHECKED_IN, ORDER_STATUSES } from '../../constants/status';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { GeneralDialogComponent } from '../../@theme/components/general-dialog/general-dialog.component';
import { BookingDetailsSelectedService } from './../akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { ServiceCentersService } from '../akita-stores/entity-stores/sercice-centers/service-centers.service';
import { APIService } from './api.service';
import { ERROR_MESSAGES } from '../../constants/error-messages';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  /**
   * The component itself somehow renders twice. This is a workaround solution to resolve it.
   *
   * @type {Observable<any>}
   * @memberof CommonService
   */
  private specialOfferDataObservable: Observable<any>;
  private beveragesDataObservable: Observable<any>;
  private logbookPackagesObservable: Observable<any>;
  private specialOfferDeleteObservable: Observable<any>;
  private specialOfferAddObservable: Observable<any>;
  openLocation = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private bookingDetailsStore: BookingDetailsSelectedStore,
    private bookingService: BookingDetailsSelectedService,
    private configStore: ConfigStore,
    private dialogService: NbDialogService,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private centersService: ServiceCentersService,
    private toastrService: NbToastrService
  ) { }

  getSpecialOffers(): Observable<any> {
    if (this.specialOfferDataObservable) {

      return this.specialOfferDataObservable;
    } else {
      this.specialOfferDataObservable = this.http.get<any>(API_PATH.publics.extraOptions).pipe(share());

      return this.specialOfferDataObservable;
    }
  }

  deleteSpecialOffer(ids: number[], bookingId) {
    const url = APIService.prepareURI(API_PATH.bookings.extraOptions, { bookingId });
    const data = { bookingAdditionalOptionIds: ids };

    return this.specialOfferDeleteObservable = this.http.request('delete', url, { body: data }).pipe(share());
  }

  addSpecialOffer(bookingAdditionalOptions: any[], bookingId) {
    const url = APIService.prepareURI(API_PATH.bookings.extraOptions, { bookingId });

    return this.http.post(url, { bookingAdditionalOptions });
  }

  getBeverages(): Observable<any> {
    if (this.beveragesDataObservable) {

      return this.beveragesDataObservable;
    } else {
      this.beveragesDataObservable = this.http.get<any>(API_PATH.publics.beverages).pipe(share());

      return this.beveragesDataObservable;
    }
  }

  calcPayingPrices(bookingDetails: BookingDetails) {
    let total = 0;
    bookingDetails.jobs.forEach(item => {
      if (item.price) {
        total += item.price
      }
    });
    bookingDetails.extraOptions.forEach(item => {
      if (item.priceExclTax) {
        total += item.priceExclTax
      }
    });

    const totalWithDiscount = total * (1 - environment.percentDiscount);
    const totalAndPaymentFee = total + this.calcPaymentFee(total);
    const totalWithDiscountAndPaymentFee = totalWithDiscount + this.calcPaymentFee(totalWithDiscount);

    return {
      total,
      totalWithDiscount,
      totalAndPaymentFee,
      totalWithDiscountAndPaymentFee
    }
  }

  calcPaymentFee(price: number) {
    return (price * environment.cardPaymentFee) + ((price * environment.cardPaymentFee) * environment.discount);
  }

  calcTotalPrice(bookingDetails: BookingDetails) {
    let total = bookingDetails.jobs[0].price;
    if (bookingDetails.bookingPayments[0] && bookingDetails.bookingPayments[0].type === 'cash') {
      total = bookingDetails.bookingPayments[0].amt;
    }
    else {
      bookingDetails.extraOptions.forEach(item => {
        total += item.priceExclTax
      });
    }
    let bookingPayment = bookingDetails.bookingPayments[0];
    let totalFee = 0;
    let totalWithDiscount = total * (1 - environment.discount);


    let isPayOnline = bookingPayment && bookingPayment.coupon && bookingPayment.coupon.code === "paynow" ? true : false;
    if (isPayOnline) {
      totalWithDiscount = (total - (total * 10 / 100));
      totalFee = totalWithDiscount * 0.93 / 100
      totalWithDiscount = totalWithDiscount + totalFee;
    }
    return {
      total,
      totalFee,
      totalWithDiscount,
    }
  }

  @Share()
  getLogbookPackages(odometer, vehicleReference?, quoteRequestKey?) {
    let url = API_PATH.bookings.logbookJobs + `?odometer=${odometer}`;
    if (vehicleReference) url += `&vehicleReference=${vehicleReference}`;
    if (quoteRequestKey) url += `&quoteRequestKey=${quoteRequestKey}`;

    return this.logbookPackagesObservable = this.http.get<any>(url);
  }

  getExpressJobId() {
    return this.http.get(API_PATH.bookings.expressJobs, { params: { cylinder: '6' } }).pipe(map((res: any) => {
      if (res.results && res.results.length > 0) {
        return res.results[0].id;
      }
    }));
  }

  isUneditableMode(bookingDetails) {
    if (bookingDetails.isEnquiry) return true;
    if (!bookingDetails.bookingStatus.code) return false;
    return !this.isStatusBeforeCheckedIn(bookingDetails.bookingStatus.code);
  }

  getBookingStatus(bookingId) {
    const url = APIService.prepareURI(API_PATH.bookings.bookingStatus, { bookingId });

    return this.http.get(url);
  }

  getUserContact(contacts, type) {
    return contacts.find(item => item.contact.type === type);
  }

  getUserAddress(address) {
    let fullAddress = `${address.address.streetLine1} ${address.address.streetLine2} ${address.address.city} ${address.address.state} ${address.address.postCode}`;

    return fullAddress.replace(/null/g, '');
  }

  getValueCommunications(arr, type) {
    let value;
    const objType = type === 'phone' || type === 'email' ? 'contact' : 'address';
    arr.forEach(item => {
      if (item[objType].type.toLowerCase() === type) {
        value = item;
      }
    });

    return value;
  }

  resetStore() {
    this.bookingDetailsStore.updateStore({});
    this.configStore.updateClonnedBookingDetails({});
    this.configStore.setInputAmount(0.00);
    this.configStore.setAction('manageBooking');
    // this.configStore.setMakingCreditCardPayment(null);
  }

  transformData(booking, serviceCenter) {
    let home = null;
    if (serviceCenter) {
      const addressCenter = serviceCenter.addresses && serviceCenter.addresses[0]
        ? serviceCenter.addresses[0].address : null;
      home = {
        name: serviceCenter.name,
        desc: this.customStrAddress(addressCenter)
      }
    }
    home.desc = home.desc.replace(/null/g, '');

    return {
      title: booking.serviceType.description,
      type: booking.serviceType.code,
      icon: booking.serviceType.code === 'service' ? 'calender' : 'express',
      url: 'vehicle-detail/vehicle-booking',
      data:
      {
        id: booking.id,
        dateTime: moment(booking.bookedAt).format('DD MMM YYYY [at] hh:mm a'),
        car: {
          name: booking.vehicle.rego.toUpperCase() + ' · ' + this.customStrInfoVehicle(booking.vehicle, true, false),
          desc: this.customStrInfoVehicle(booking.vehicle, false, true)
        },
        home: home,
        bookingStatus: booking.bookingStatus.code
      }
    }
  }

  getReceiptValue(bookingDetails: BookingDetails, isChkDiscount: boolean = false) {
    const isExpress = this.isExpress(bookingDetails.serviceType.code);
    let calcTotal = 0;
    const paymentsCompleted = this.getPaymentCompleted(bookingDetails);
    const isPaymentCompleted = this.isPaymentCompleted(bookingDetails);
    const isPayOnline = isPaymentCompleted && paymentsCompleted.type !== 'cash';
    const isCoupon = isPaymentCompleted && paymentsCompleted.coupon ? true : false;
    const isExpressAndPayOnline = isExpress && isPayOnline;
    const isExpressAndChkDiscount = isExpress && !isPaymentCompleted && isChkDiscount;
    const statusCode = bookingDetails.bookingStatus.code;
    const prices = [];

    // calc jobs
    if (!_.isEmpty(bookingDetails.jobs)) {
      bookingDetails.jobs.forEach((job => {
        const nameJob = job.provider === 'repco'
          ? (this.isExpress(job.serviceType.code) ? 'Express Service' : this.getLargestKmLogbookService(job.km + '', 'KM Service'))
          : (job.serviceType.description || job.name);
        let priceJob: string | number = 0;
        if (statusCode === 'service-booked' && this.isLogbook(job.serviceType.code)) {
          priceJob = job.isFixedPrice ? job.adjustedPrice : '-';
        } else {
          priceJob = !job.adjustedPrice && !job.price
            ? (this.isExpress(job.serviceType.code) ? 'P.O.A' : '-')
            : (job.isFixedPrice ? job.adjustedPrice : job.price);
        }
        let priceJobDiscount: string | number = isExpressAndPayOnline
          ? (!job.adjustedPriceDiscount && !job.priceDiscount ? 'P.O.A' : (job.isFixedPrice ? job.adjustedPriceDiscount : job.priceDiscount))
          : null;
        if (isExpressAndChkDiscount) priceJobDiscount = this.calcPriceDiscount(priceJob);

        if (!isPaymentCompleted || (isPaymentCompleted && job.paymentId)) {
          prices.push({
            name: nameJob,
            price: this.transformCurrency(priceJob),
            priceDiscount: this.transformCurrency(priceJobDiscount)
          });
        }
        if ((isExpress && !isPayOnline) || (!isExpress && statusCode !== 'service-booked')) calcTotal += job.price;
      }));
    }

    // calc extra options
    if (!_.isEmpty(bookingDetails.extraOptions)) {
      bookingDetails.extraOptions.forEach((extra) => {
        const priceExtra = extra.priceExclTax === 0
          ? (extra.isBeverage ? 'Free' : 'P.O.A')
          : extra.priceExclTax;
        let priceDiscountExtra: any = isExpressAndPayOnline
          ? (extra.priceExclTax === 0 ? (extra.isBeverage ? 'Free' : 'P.O.A') : extra.priceDiscount)
          : null
        if (isExpressAndChkDiscount) priceDiscountExtra = this.calcPriceDiscount(priceExtra);
        prices.push({
          name: extra.name,
          price: this.transformCurrency(priceExtra),
          priceDiscount: this.transformCurrency(priceDiscountExtra)
        });
        if ((isExpress && !isPayOnline) || (!isExpress && statusCode !== 'service-booked')) calcTotal += extra.priceExclTax;
      });
    }

    const isPayCash = isPaymentCompleted && paymentsCompleted.type === 'cash';
    const isPaymentFee = isExpressAndPayOnline && paymentsCompleted.type === 'credit-card';

    if (isExpress) {
      calcTotal = isPayOnline
        ? (isCoupon ? paymentsCompleted.amtExclDiscount : paymentsCompleted.amt)
        : (isPayCash ? paymentsCompleted.amt : calcTotal);
    }

    if (this.isLogbook(bookingDetails.serviceType.code)) {
      calcTotal = isPayCash ? paymentsCompleted.amt : (calcTotal || null);
    }

    let calcTotalDiscount = isExpressAndPayOnline
      ? (isPaymentFee ? paymentsCompleted.amt : paymentsCompleted.amtExclPaymentFee)
      : null;

    if (isExpressAndChkDiscount) calcTotalDiscount = this.calcPriceDiscount(calcTotal);

    const priceTaxs = this.calcPriceTax(calcTotal, bookingDetails, isExpressAndChkDiscount);

    const isPayWithDiscount = isExpressAndPayOnline && isCoupon;

    return {
      prices,
      total: this.transformCurrency(calcTotal || '-'),
      totalDiscount: this.transformCurrency(calcTotalDiscount),
      paymentFeeAmt: isPaymentFee ? this.transformCurrency(paymentsCompleted.paymentFeeAmt) : null,
      isExpressAndPayOnline,
      isPaymentCompleted,
      isPaymentFee,
      priceTaxs,
      isChkDiscount,
      isPayWithDiscount,
    }
  }

  getKmService(kmStr: string, text) {
    let arrKm = kmStr.split(',');
    arrKm = arrKm.map(km => (km ? this.decimalPipe.transform(km) : 0) + ` ${text}`);

    return arrKm.join(arrKm.length > 1 ? '; ' : '');
  }

  transformCurrency(val: number | string) {
    if (val === null) return null;
    return typeof val === 'number' ? this.currencyPipe.transform(val) : val;
  }

  calcPriceDiscount(price: number | string) {
    return typeof price === "number" ? (price - (price * environment.discount)) : price;
  }

  calcPriceTax(total: number, bookingDetails: BookingDetails, isChkDiscount: boolean = false) {
    const paymentsCompleted = this.getPaymentCompleted(bookingDetails);
    const isPaymentCompleted = this.isPaymentCompleted(bookingDetails);
    if (isPaymentCompleted) {
      return {
        subTotal: this.transformCurrency(paymentsCompleted.subtotal),
        subTotalDiscount: this.transformCurrency(paymentsCompleted.subtotalExclDiscount),
        gst: this.transformCurrency(paymentsCompleted.gst),
        gstDiscount: this.transformCurrency(paymentsCompleted.gstExclDiscount),
        saved: this.transformCurrency(paymentsCompleted.saved)
      };
    } else {
      if (isChkDiscount) {
        const totalDiscount: any = this.calcPriceDiscount(total);
        return {
          subTotal: this.transformCurrency(totalDiscount / 1.1),
          subTotalDiscount: this.transformCurrency(total / 1.1),
          gst: this.transformCurrency(totalDiscount - (totalDiscount / 1.1)),
          gstDiscount: this.transformCurrency(total - (total / 1.1)),
          saved: this.transformCurrency(total - totalDiscount)
        }
      } else {
        return total
          ? { subTotal: this.transformCurrency(total / 1.1), gst: this.transformCurrency(total - (total / 1.1)) }
          : { subTotal: '-', gst: '-' };
      }
    }
  }

  getPaymentCompleted(bookingDetails: BookingDetails) {
    return _.isEmpty(bookingDetails.bookingPayments) ? {} : _.find(bookingDetails.bookingPayments, (o) => o.status === 'completed');
  }

  isPaymentCompleted(bookingDetails: BookingDetails) {
    return _.isEmpty(this.getPaymentCompleted(bookingDetails)) ? false : true;
  }

  isExpress(serviceTypeCode: string) {
    return serviceTypeCode.toUpperCase() === BookingEnum.EXPRESS;
  }

  isLogbook(serviceTypeCode: string) {
    return serviceTypeCode.toUpperCase() === BookingEnum.LOGBOOK;
  }

  getIconProcessStatus(statusCode: string, iconBurnOutCircle: boolean = true): string {
    let icon = iconBurnOutCircle ? 'process-service-booked' : 'circle-plus';
    switch (statusCode) {
      case "service-booked":
        icon = iconBurnOutCircle ? 'process-service-booked' : 'circle-plus';
        break;
      case "qualified":
        icon = iconBurnOutCircle ? 'process-qualified' : 'circle-check-transparent';
        break;
      case "checked-in":
        icon = iconBurnOutCircle ? 'process-checked-in' : 'login';
        break;
      case "vehicle-health-check":
        icon = iconBurnOutCircle ? 'process-vehicle-health-check' : 'edit-square';
        break;
      case "work-in-progress":
        icon = iconBurnOutCircle ? 'process-work-in-progress' : 'car';
        break;
      case "special-offer-wip":
        icon = iconBurnOutCircle ? 'process-offer-wip' : 'setting';
        break;
      case "quality-assurance":
        icon = iconBurnOutCircle ? 'process-quality-assurance' : 'shield';
        break;
      case "wash-bay":
        icon = iconBurnOutCircle ? 'process-wash-bay' : 'droplet';
        break;
      case "invoiced":
        icon = iconBurnOutCircle ? 'process-invoiced' : 'dollar-sign';
        break;
      case "ready-for-pickup":
        icon = iconBurnOutCircle ? 'process-picked-up' : 'circle-check-ready';
        break;
    }
    return icon;
  }

  isStatusBeforeCheckedIn(statusCode: string) {
    return STATUS_BEFORE_CHECKED_IN.includes(statusCode) ? true : false;
  }

  customStrInfoVehicle(vehicle, enableName: boolean = true, enableColourCylinder: boolean = true) {
    if (!vehicle) return '';
    let strInfoVehicle = '';
    if (enableName) {
      if (vehicle.make) strInfoVehicle += vehicle.make.toUpperCase();
      if (vehicle.model) strInfoVehicle += ' ' + vehicle.model.toUpperCase();
      if (vehicle.year) strInfoVehicle += ' ' + vehicle.year;
    }
    if (enableColourCylinder) {
      if (vehicle.colour) strInfoVehicle += ' · ' + vehicle.colour;
      if (vehicle.cylinder) strInfoVehicle += ' · ' + vehicle.cylinder + ' Cylinders'
    }
    const strFirstThree = strInfoVehicle.substring(0, 3);
    if (strFirstThree === ' · ') return strInfoVehicle.slice(3);
    return strInfoVehicle.trim();
  }

  customLocationAddress(address, isLocationAddress) {
    if (!address) return '-';
    let strAddress = '';
    if (isLocationAddress) {
      if (address.streetAddress) strAddress += address.streetAddress;
      if (address.streetLine1) strAddress += ' ' + address.streetLine1;
      if (address.streetLine2) strAddress += ' ' + address.streetLine2;
      if (strAddress) strAddress += ',';
      if (address.suburb) strAddress += ' ' + this.capitalizeSuburb(address.suburb);
      if (!strAddress) return '-';
      return strAddress.trim();
    }
    if (address.state) strAddress += ' ' + address.state;
    if (address.postCode) strAddress += ' ' + address.postCode;
    if (!strAddress) return '-';
    return strAddress.trim();
  }

  customStrAddress(address) {
    if (!address) return '-';
    let strAddress = '';
    if (address.streetAddress) strAddress += address.streetAddress;
    if (address.streetLine1) strAddress += ' ' + address.streetLine1;
    if (address.streetLine2) strAddress += ' ' + address.streetLine2;
    if (address.streetAddress || address.streetLine1 || address.streetLine2) strAddress += ',';
    if (address.suburb) strAddress += ' ' + this.capitalizeSuburb(address.suburb);
    if (address.state) strAddress += ' ' + address.state;
    if (address.postCode) strAddress += ' ' + address.postCode;
    if (!strAddress) return '-';
    return strAddress.trim();
  }

  capitalizeSuburb(str) {
    return str.split(' ').map(suburb => _.capitalize(suburb)).join(' ');
  }

  pickUp(bookingDetails?, callbackFn?) {
    return this.dialogService.open(GeneralDialogComponent, {
      hasBackdrop: true, closeOnBackdropClick: false,
      context: {
        text: 'This booking has been completed. <br/>Please confirm that customer has picked up this vehicle.',
        buttonCancelTitle: 'Cancel',
        buttonAcceptTitle: 'Yes',
        isSendEmit: true
      }
    });
  }

  removeWhiteSpacesStr(str: string) {
    return str.replace(/\s/g, '');
  }

  listToTree(list: any[] = [], selectedEnityId?, objIdKeyName: string = 'id', parentIdKeyName: string = 'parentId', childrenKeyName = 'children'): any {
    // if (list.length === 0) {
    //   list = [
    //     { "id": 1, "name": "Root", "code": "R000", "type": "root" },
    //     { "id": 2, "name": "AP Eager", "code": "C000", "type": "company", "parentId": 1 },
    //     { "id": 3, "name": "MO:", "code": "C001", "type": "company", "parentId": 2 },
    //     { "id": 4, "name": "Caloundra Service Centre", "code": "S001", "type": "service", "parentId": 3 },
    //     { "id": 5, "name": "Service Center 2", "code": "S002", "type": "service", "parentId": 3 }
    //   ];
    // }

    let obj = {}, root = {};
    list.forEach(item => {
      obj[item[objIdKeyName]] = item;
    });

    root = {};
    list.forEach(item => {
      item.expanded = true;
      item.selected = item.id === selectedEnityId;
      item.title = item.name;
      if (item[parentIdKeyName]) {
        if (!obj[item[parentIdKeyName]][childrenKeyName]) {
          obj[item[parentIdKeyName]][childrenKeyName] = [];
        }
        const duplicated = obj[item[parentIdKeyName]][childrenKeyName].find(c => c.id === item.id);
        if (!duplicated) {
          obj[item[parentIdKeyName]][childrenKeyName].push(item);
        }
      } else {
        root = item;
      }
    });
    return root;
  }

  getFullName(firstName?: string, lastName?: string) {
    return ((firstName || '') + ' ' + (lastName || '')).trim();
  }

  convertCodeToText(code) {
    return code.replace('-', ' ').toLowerCase();
  }

  convertObjToHttpParams(obj) {
    let httpParams = new HttpParams();
    Object.keys(obj).forEach(function (key) {
      httpParams = httpParams.append(key, obj[key]);
    });
    return httpParams;
  }

  showDetailErrorMessages(errorObj) {
    try {
      const getDetailMessage = (details) => {
        let msg = '';
        details.forEach(item => {
          msg += ' ' + item;
        });
        return msg;
      }
      const { fields } = errorObj.error;
      let errorMessages = '';
      fields.forEach(field => {
        let err = `${field.Name} -${getDetailMessage(field.Details)}`;
        errorMessages += '\n' + err;
      });
      if (!errorMessages) errorMessages = ERROR_MESSAGES.internalIssue;
      this.toastrService.danger(errorMessages, 'Error', { destroyByClick: true, hasIcon: false, preventDuplicates: true });
    } catch (e) { }
  }

  dataURItoBlob(dataURI, contentType) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });
    return blob;
  }

  blobToFile(blob: Blob, fileName: string): File {
    const b: any = blob;
    const currentTime = new Date();
    b.lastModified = currentTime.getTime();
    b.lastModifiedDate = currentTime;
    b.name = fileName;
    return <File>blob;
  }

  transformStringToArray(value: string | string[]): string[] {
    if (!!value && typeof value === 'string') {
      return [value];
    }
    return [].concat(value);
  }

  /** check current status is before or after origin status
   * type: ['before', 'after', 'beforeFrom', 'afterFrom'] */
  isCheckStatusPosition(originStatus: string, currentStatus: string, type: string): boolean {
    const orderStatusCode = ORDER_STATUSES.map(status => status.code);
    const indexOriginStatus = orderStatusCode.findIndex(status => status === originStatus);
    let conditionFilter;
    if (type === 'beforeFrom') {
      conditionFilter = (item, index) => index <= indexOriginStatus;
    } else if (type === 'afterFrom') {
      conditionFilter = (item, index) => index >= indexOriginStatus;
    } else if (type === 'before') {
      conditionFilter = (item, index) => index < indexOriginStatus;
    } else if (type === 'after') {
      conditionFilter = (item, index) => index > indexOriginStatus;
    } else {
      return false;
    }
    const results = orderStatusCode.filter(conditionFilter);
    return results.includes(currentStatus);
  }

  /** get largest odometer in a string odometer
   * strOdometers: '140000,150000,160000'
  */
  getLargestOdometer(strOdometers: string) {
    const odometers = strOdometers.split(',').map(odometer => +odometer);
    return Math.max(...odometers);
  }

  getLargestKmLogbookService(kmStr: string, text: string) {
    const largestKm = this.getLargestOdometer(kmStr);
    return (largestKm ? this.decimalPipe.transform(largestKm) : 0) + ` ${text}`;
  }

  getServiceTypes(noEntityContext?: boolean) {
    let params = '';
    if (noEntityContext) {
      params += '?entityContext=-1';
    }
    const url = API_PATH.bookings.serviceTypes + params;
    return this.http.get(url).pipe(map((res: any) => res.results || []));
  }

  private getContentDispositionHeaderFilename(contentDisposition) {
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = regex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
    return null;
  }

  exportContentDispositionFile(res: HttpResponse<Object>) {
    const contentDispositionHeader = res.headers.get('Content-Disposition')
    const fileName = this.getContentDispositionHeaderFilename(contentDispositionHeader)
    if(fileName) {
      const blob = res.body as Blob
      const a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    }
  }

  isTBAJobCode(jobCode: string) {
    // BE confirmed: '-tba-' in job code can be used to indicate that the job has TBA price
    return jobCode && _.includes(_.toLower(jobCode), '-tba-');
  }
}
