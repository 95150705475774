import { EntityState, EntityStore, StoreConfig, ID } from '@datorama/akita';
import { Injectable } from '@angular/core';

export interface EntityItem {
  id: ID;
  name: string;
  code: string;
  type: string;
  parentId?: number;
  objectId?: number;
  childs?: EntityItem[];
}

export interface EntitiesTree {
  list: EntityItem[],
  tree: EntityItem
}

export interface EntitiesState extends EntityState<EntityItem, number> {
  entitiesTree: EntitiesTree
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'entities' })
export class EntitiesStore extends EntityStore<EntitiesState> {
  constructor() {
    super();
  }
}