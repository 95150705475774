import { Component, Input, ViewChild, OnInit, Output, EventEmitter, TemplateRef, AfterViewInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ColumnMode, DatatableComponent, NgxDatatableModule, TableColumn } from '@swimlane/ngx-datatable';
import { NbSidebarService } from '@nebular/theme';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { CUSTOMER_PERMISSIONS, SERVICE_CENTER_PERMISSIONS } from '../../../constants';
import { FlightDeckQuery } from '../../../@core/akita-stores/stores/flightdeck/flightdeck.service';
import { interval } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

export interface CustomTableColumnConfig extends TableColumn {
  align?: string;
  bold?: boolean;
  format?: string;
  progress?: boolean;
  mask?: string;
  icon?: boolean;
  tag?: boolean;
  isTime?: boolean;
  cylinders?: boolean;
  image?: boolean;
  detail?: boolean;
  serviceTime?: boolean;
  iconType?: boolean;
  hasClick?: boolean;
  iconStatus?: boolean;
  customTemplate?: any;
  cellClass?: string;
}

@Component({
  selector: 'custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)',
  },
})
export class CustomTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: string;
  @Input() rows: any[];
  @Input() cols: CustomTableColumnConfig[];
  @Input() columnMode: string;
  @Input() paginationMode: any;
  @Input() pageInfo: any;
  @Input() isReception: boolean;
  @Input() expanded = false;
  @Input() isFlightDeckList: boolean;
  @Input() detailTemplateRef: TemplateRef<any>;
  @Input() hideShowNumberRows: boolean;
  @Input() scrollbarH: boolean;
  @Output() clickRow = new EventEmitter<any>();
  @Output() scroll = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();
  @Output() resetSort = new EventEmitter<any>();
  @Output() buttonClick = new EventEmitter<any>();
  @Output() changePage = new EventEmitter<any>();
  @Output() changeLimit = new EventEmitter<any>();
  @Output() clickCell = new EventEmitter<any>();
  @Output('getValueExpand') rowExpand = new EventEmitter<any>();

  @ViewChild(DatatableComponent, { static: true }) ngxTable: DatatableComponent;

  ColumnMode = ColumnMode;
  isPermissions = {
    getCustomer: false,
    adminGetCenterDetails: false
  }
  pageSize: number = 10;
  enableCursorRow: boolean = true;
  scrollUpdate$;
  scrollCheck$;
  isScrolling: boolean = false;

  constructor(
    private nbSidebarService: NbSidebarService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    public flightDeckQuery: FlightDeckQuery
  ) {
    Promise.all([
      this.permissionsService.hasPermission(CUSTOMER_PERMISSIONS.getCustomer),
      this.permissionsService.hasPermission(SERVICE_CENTER_PERMISSIONS.adminGetServiceCenterDetails)
    ]).then(([hasGetCustomer, hasAdminGetCenterDetails]) => {
      this.isPermissions.getCustomer = hasGetCustomer;
      this.isPermissions.adminGetCenterDetails = hasAdminGetCenterDetails;
    }).catch(err => console.error(err));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.handleLimitItem();
    if (changes.rows && changes.rows.currentValue) {
      this.expendRows();
    }
    if (changes.expanded && this.rows) {
      this.expendRows();
    }
  }

  private expendRows(): void {
    if (this.expanded) {
      Promise.resolve().then(() => {
        this.ngxTable.rowDetail.expandAllRows();
      });
    }else{
      Promise.resolve().then(() => {
        this.ngxTable.rowDetail.collapseAllRows();
      });
    }

  }

  ngOnInit() {
    this.columnMode = this.columnMode && this.columnMode === "force" ? "force" : "flex";
    this.onRecaculate();
    this.nbSidebarService.onToggle().subscribe(() => {
      this.onRecaculate();
    });
    this.handleCursorRow();

    if (this.isReception) {
        this.scrollCheck$ = interval(30 * 1000).subscribe(() => {
            if (!this.isScrolling) {
                this.isScrolling = true;
                if (this.scrollUpdate$) this.scrollUpdate$.unsubscribe();
                this.scrollUpdate$ = this.updateScrollTimer().subscribe(() => {
                    this.updateScrollPosition();
                });
            }
        })
    }
  }

  onRecaculate() {
    setTimeout(() => {
      this.ngxTable.recalculate();
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  onResize(event) {
    this.onRecaculate();
  }

  onActivate(event) {
    if (this.type === 'customers' && !this.isPermissions.getCustomer) return;
    if (this.type === 'service-centres' && !this.isPermissions.adminGetCenterDetails) return;
    if (event.type == 'click') {
      event.cellElement.blur();
      this.clickRow.emit(event.row);
    }
  }

  handleClickCell(val) {
    this.clickCell.emit(val);
  }

  onScroll(event: number) {
    this.scroll.emit(event);
  }

  onSort(event) {
    this.sort.emit(event);
  }

  onResetSort(sorts: any[] = []) {
    this.ngxTable.sorts = sorts;
  }

  scrollToTop() {
    this.ngxTable.offset = 0;
  }

  handleCursorRow() {
    if (
      (this.type === 'customers' && !this.isPermissions.getCustomer) ||
      (this.type === 'service-centres' && !this.isPermissions.adminGetCenterDetails)
    ) {
      this.enableCursorRow = false;
    }
  }

  toggleExpandRow(row, e, expanded) {
    this.rowExpand.emit({ row: row, event: e, expanded: expanded })
    e.stopPropagation();
    this.ngxTable.rowDetail.toggleExpandRow(row);
  }

  assignButton(row, e) {
    this.buttonClick.emit({ row: row, event: e });
  }

  setPage(event) {
    this.changePage.emit(event);
  }

  setLimitItem(limit) {
    this.changeLimit.emit(limit);
  }

  handleLimitItem() {
    if (this.paginationMode) {
      this.pageSize = this.pageInfo && this.pageInfo.limit ? this.pageInfo.limit : this.pageSize;
    } else {
      this.pageSize = undefined;
    }
  }

  getRowClass(row) {
    const showOverTime = !row.isReception && row.isOverTime;
    return {
      'has-hrefLink': !!row.hrefLink,
      'over-time': showOverTime,
      'normal': !showOverTime,
    };
  }

  updateScrollTimer() {
    return interval(2 * 1000).pipe(takeWhile(() => this.isScrolling));
  }

  updateScrollPosition() {
    const scrollTop = this.ngxTable.element.getElementsByTagName('datatable-body')[0].scrollTop;
    const clientHeight = this.ngxTable.element.getElementsByTagName('datatable-body')[0].clientHeight;
    const scrollHeight = this.ngxTable.element.getElementsByTagName('datatable-body')[0].scrollHeight;
    const atBottom = scrollTop + clientHeight >= scrollHeight;

    if (!atBottom) {
        this.ngxTable.element.getElementsByTagName('datatable-body')[0].scrollTop = scrollTop + 50;
        this.isScrolling = true;
    } else {
        this.ngxTable.element.getElementsByTagName('datatable-body')[0].scrollTop = 0;
        this.isScrolling = false;
    }
  }

  ngOnDestroy(): void {
    if (this.scrollUpdate$) this.scrollUpdate$.unsubscribe();
    if (this.scrollCheck$) this.scrollCheck$.unsubscribe();
  }
}
