import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ERROR_MESSAGES } from '../../../constants';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged } from 'rxjs/operators';

export type DateRangeOptionsValue =
  | 'today'
  | 'yesterday'
  | 'this-week'
  | 'last-week'
  | 'this-month'
  | 'last-month'
  | 'quarter-to-date'
  | 'year-to-date'
  | 'last-year'
  | 'custom-date'
  | 'date-range';
  
export interface DateRangeOption {
  label: string;
  value: DateRangeOptionsValue;
  timeRange: [string, string] | [];
}

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.conponent.html',
  styleUrls: ['./date-range-picker.conponent.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class DateRangePicker implements OnInit {
  @ViewChild('rangepicker', { static: true }) rangepickerEle: ElementRef;
  @Output() onDateChange = new EventEmitter();
  @Input() dateRangeValue = [];
  @Input() initDateRangeOptions: Array<DateRangeOptionsValue | Partial<DateRangeOption>> = null;
  @Input() initRangeOption: DateRangeOptionsValue = 'this-month';
  private subjecValueSubmit = new Subject<any>();

  dateFormat = 'DD/MM/YYYY';
  form = new FormGroup({
    fromDay: new FormControl(moment().format(this.dateFormat), [
      Validators.required,
    ]),
    toDay: new FormControl(moment().format(this.dateFormat), [
      Validators.required,
    ]),
    rangeDateOptions: new FormControl('this-month'),
    rangeDateInput: new FormControl(),
    start: new FormControl(null),
    end: new FormControl(null),
  });

  utcOffset = moment().utcOffset(); // minutes
  isCustomDay = false;
  errorMessage = ERROR_MESSAGES;
  dateToday = new Date();
  isSyncRangepicker = false;

  dateRangeOptions: DateRangeOption[] = [
    {
      label: 'Today',
      value: 'today',
      timeRange: [
        moment().format(this.dateFormat),
        moment().format(this.dateFormat),
      ],
    },
    {
      label: 'Yesterday',
      value: 'yesterday',
      timeRange: [
        moment().subtract(1, 'days').format(this.dateFormat),
        moment().subtract(1, 'days').format(this.dateFormat),
      ],
    },
    {
      label: 'This Week',
      value: 'this-week',
      timeRange: [
        moment().weekday(0).format(this.dateFormat),
        moment().weekday(6).format(this.dateFormat),
      ],
    },
    {
      label: 'Last Week',
      value: 'last-week',
      timeRange: [
        moment().subtract(1, 'weeks').weekday(0).format(this.dateFormat),
        moment().subtract(1, 'weeks').weekday(6).format(this.dateFormat),
      ],
    },
    {
      label: 'This Month',
      value: 'this-month',
      timeRange: [
        moment().subtract(0, 'month').startOf('M').format(this.dateFormat),
        moment().subtract(0, 'month').endOf('M').format(this.dateFormat),
      ],
    },
    {
      label: 'Last Month',
      value: 'last-month',
      timeRange: [
        moment().subtract(1, 'month').startOf('M').format(this.dateFormat),
        moment().subtract(1, 'month').endOf('M').format(this.dateFormat),
      ],
    },
    {
      label: 'Quarter to Date',
      value: 'quarter-to-date',
      timeRange: [
        moment()
          .quarter(moment().quarter())
          .startOf('quarter')
          .format(this.dateFormat),
        moment().format(this.dateFormat),
      ],
    },
    {
      label: 'Year to Date',
      value: 'year-to-date',
      timeRange: [
        moment().startOf('year').format(this.dateFormat),
        moment().format(this.dateFormat),
      ],
    },
    {
      label: 'Last Year',
      value: 'last-year',
      timeRange: [
        moment().subtract(1, 'year').startOf('year').format(this.dateFormat),
        moment().subtract(1, 'year').endOf('year').format(this.dateFormat),
      ],
    },
    {
      label: 'Custom Date',
      value: 'custom-date',
      timeRange: [
        // today
        // moment().format(this.dateFormat),
        // moment().format(this.dateFormat),
      ],
    },
    {
      label: 'Date Range',
      value: 'date-range',
      timeRange: [],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    this.getInitDateRangeOptions();

    this.form.controls['rangeDateOptions'].valueChanges.subscribe((value) => {
      const options = this.dateRangeOptions.find(
        (item) => item.value === value
      );
      if (value === 'custom-date' || value === 'date-range') {
        this.isCustomDay = true;
      } else {
        this.isCustomDay = false;
      }
      if (options.timeRange && options.timeRange.length > 1) {
        const [fromDay, toDay] = options.timeRange;
        if (fromDay && toDay) {
          this.form.controls['fromDay'].setValue(fromDay);
          this.form.controls['toDay'].setValue(toDay);
        }
      }
    });

    this.form.controls['fromDay'].valueChanges.subscribe((value) => {
      this.dateInputValidate(value, 'fromDay');
    });

    this.form.controls['toDay'].valueChanges.subscribe((value) => {
      this.dateInputValidate(value, 'toDay');
    });

    this.form.controls['rangeDateInput'].valueChanges.subscribe(
      (value: { start: Date; end?: Date }) => {
        if (this.isSyncRangepicker) {
          this.isSyncRangepicker = false;
          return;
        }
        const { start, end } = value;
        if (start && end) {
          this.form.controls['fromDay'].setValue(
            moment(start).format('DDMMYYYY')
          );
          this.form.controls['toDay'].setValue(moment(end).format('DDMMYYYY'));
        } else {
          // this.form.controls['fromDay'].setValue(moment(start).format("DDMMYYYY"))
          // this.form.controls['toDay'].setValue(moment(start).format("DDMMYYYY"))
        }
      }
    );

    this.subjecValueSubmit
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value) => {
        this.submit(value);
      });
  
    this.form.controls['rangeDateOptions'].setValue(this.initRangeOption); // init value
  }

  ngOnChanges(changed) {
    if (changed.dateRangeValue && changed.dateRangeValue.currentValue) {
      const [start, end] = changed.dateRangeValue.currentValue;

      // if start and end is the same day with option day value, change to the option value else set to custom date
      const option = this.dateRangeOptions.find(
        (option) =>
          option.timeRange &&
          option.timeRange[0] === moment(start).format(this.dateFormat) &&
          option.timeRange[1] === moment(end).format(this.dateFormat)
      );

      if (option) {
        this.form.controls['rangeDateOptions'].setValue(option.value);
      } else {
        this.form.controls['rangeDateOptions'].setValue('date-range');
        this.form.controls['rangeDateInput'].setValue({
          start: moment(start).toDate(),
          end: moment(end).toDate(),
        });
      }
    }
  }

  private getInitDateRangeOptions() {
    if (!this.initDateRangeOptions) return;
    const allDateRangeOptions = structuredClone(this.dateRangeOptions);

    this.dateRangeOptions = this.initDateRangeOptions.map((item) => {
      if (typeof item === 'string') {
        return allDateRangeOptions.find((option) => option.value === item);
      } else {
        const value = item.value;
        const option = structuredClone(allDateRangeOptions.find((option) => option.value === value));
        if (option) {
          if (item.timeRange) {
            option.timeRange = item.timeRange;
          }
          if (item.label) {
            option.label = item.label;
          }
          return option;
        }
      }
    });
  }

  dateInputValidate(value: string, inputName: string) {
    this.form.controls[inputName].setErrors(null);
    const day = moment(this.getTimeFormat(value), this.dateFormat);

    if (value && value.length < 8 || !day.isValid()) {
      this.form.controls[inputName].setErrors({
        dayInvalid: true,
      });
      return;
    };

    // if from date is greater than to date, set to date to from date
    if (inputName === 'fromDay') {
      const toDay = moment(this.form.controls['toDay'].value, 'DDMMYYYY');
      if (day.isAfter(toDay)) {
        this.form.controls['toDay'].setValue(day.format('DDMMYYYY'));
      }
    }

    // if to date is less than from date, set from date to to date
    if (inputName === 'toDay') {
      const fromDay = moment(this.form.controls['fromDay'].value, 'DDMMYYYY');
      if (day.isBefore(fromDay)) {
        this.form.controls['fromDay'].setValue(day.format('DDMMYYYY'));
      }
    }

    const start = moment(this.form.controls['fromDay'].value, 'DDMMYYYY');
    const end = moment(this.form.controls['toDay'].value, 'DDMMYYYY');
    this.isSyncRangepicker = true;
    this.subjecValueSubmit.next({ start: start, end: end });
    this.form.controls['rangeDateInput'].setValue({
      start: start.add(this.utcOffset, 'minutes').toDate(),
      end: end.add(this.utcOffset, 'minutes').toDate(),
    });
  }

  submit(value) {
    const dateRangeType = this.form.controls['rangeDateOptions'].value;
    this.onDateChange.emit({
      ...value,
      dateRangeType,
    });
  }

  getTimeFormat(value) {
    return value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(-4);
  }

  openRangeicker() {
    if (!this.isCustomDay) return;
    this.rangepickerEle.nativeElement.click();
  }

  onSelectClick(e) {
    setTimeout(() => {
      const listContainer = document.getElementsByClassName('options-list');
      listContainer.length && listContainer[0].scroll({ top: 0 });
    }, 50); // TODO Temporarily solve the problem of scrolling incorrectly during the first render, please check
  }
}
