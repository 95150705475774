import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_PATH } from '../../../../constants/api-urls';
import { tap } from 'rxjs/operators';
import { EntitiesStore } from './entities.store';
import { ConfigQuery } from '../../stores/config/config.store';
import { BehaviorSubject } from 'rxjs';
import { LINK_IMAGE } from '../../../../constants/image';

@Injectable({ providedIn: 'root' })
export class EntitiesService {
  locationAutomall = new BehaviorSubject<boolean>(false);
  constructor(
    private http: HttpClient,
    private entitiesStore: EntitiesStore,
    private configQuery: ConfigQuery
  ) {}

  getEntities(isTree: boolean = true, isPublic?: boolean, noEntityContext?: boolean) {
    let params = '';
    if (isTree) {
      params = '?includes=tree';
    }
    if (noEntityContext) {
      params += '&entityContext=-1';
    }
    const url = (isPublic ? API_PATH.entities.publics : API_PATH.entities.entities) + params;
    return this.http.get(url).pipe(
      tap((entities: any) => {
        this.entitiesStore.upsertMany(entities.list);
        this.entitiesStore.update({entitiesTree: entities});
      })
    )
  }

  private setFavicon(link) {
    const favIcon: HTMLLinkElement = document.querySelector('#favicon');
    favIcon.href = link;
  }

  setTheme(parentId?) {
    const companies = this.configQuery.getCompanies;
    const automall = companies && companies.length
      ? companies.find((company) => company["name"] == "Automall")
      : null;
    const defaultColours = {
      "--block-colour": "#ab1021",
      "--orange-gradient":
        "linear-gradient(90deg, #de2c40 0%, #de142a 49.48%, #ab1021 100%)",
    };
    if (parentId && companies && parentId == automall["entityId"])
      this.setFavicon(LINK_IMAGE.FAVICON_AUTOMALL);
    else this.setFavicon(LINK_IMAGE.FAVICON);
    const theme =
      parentId && companies && parentId == automall["entityId"]
        ? {
            "--block-colour": "#1fbecd",
            "--orange-gradient":
              "linear-gradient(90deg, #50c1cc 0%, #3ac2cf 49.48%, #21c2d1 100%)"
          }
        : {};
    this.locationAutomall.next(
      parentId && companies && parentId == automall["entityId"]
    );
    const container = document.querySelector("html");
    const colours = Object.assign({}, defaultColours, theme);
    Object.keys(colours).forEach((index) => {
      let colour = colours[index];
      container.style.setProperty(index, colour);
    });
  }
}