<form [formGroup]="centreSelectForm" (ngSubmit)="onSubmit()" (keydown)="preventKeyTrigger($event)">
  <nb-card class="confirm-dlg">
    <nb-card-header>Add Service Centre <nb-icon icon="x-icon" pack="mo-icons"
        (click)=close()></nb-icon></nb-card-header>

    <nb-card-body class="d-flex flex-column p-4">
      <div class="company-select row mb-3">
        <label class="col-4"><span class="required-tick">* </span>Company</label>
        <nb-select class="col-8" formControlName="company" fullWidth placeholder="Pick company"
          [class.disabled]="editId">
          <nb-option class="single-select" *ngFor="let company of companyList" [value]="company.id">
            {{ company.name }}
          </nb-option>
        </nb-select>
      </div>

      <div class="transfer-select">
        <div class="select-list">
          <h5>LIST OF SERVICE CENTRES</h5>
          <div class="select-box">
            <div class="select-box-header">
              <mat-checkbox [checked]="isSourceCheckAll" (click)="handleSelectTransferList($event, null, true, true)">
                {{ sourceSelectedListSearched.length }}/{{ sourceListSearched.length }} item{{ sourceListSearched.length
                > 1 ? 's' : '' }}
              </mat-checkbox>
            </div>
            <div class="select-box-body">
              <label class="list-search" for="search-input">
                <input type="text" id="search-input" nbInput fullWidth class="search-input" placeholder="Search"
                  formControlName="sourceSearchValue" />
                <label for="search-input" class="search-icon">
                  <nb-icon icon="search" pack="mo-icons"></nb-icon>
                </label>
              </label>

              <div class="check-list">
                <mat-checkbox *ngFor="let item of sourceListSearched"
                  [checked]="isSelectCheck(item, sourceSelectedList)"
                  (click)="handleSelectTransferList($event, item, true)">
                  {{ item.name }}
                </mat-checkbox>

                <div class="no-data" *ngIf="sourceList.length === 0">
                  <nb-icon icon="empty-box" pack="mo-icons"></nb-icon>
                  <span>No data</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="select-actions">
          <button class="btn" [class.disabled]="sourceSelectedList.length === 0"
            [disabled]="sourceSelectedList.length === 0" (click)="handleTransfer($event, true)">
            <nb-icon icon="chevron-right" pack="mo-icons"></nb-icon>
          </button>
          <button class="btn" [class.disabled]="targetSelectedList.length === 0"
            [disabled]="targetSelectedList.length === 0" (click)="handleTransfer($event, false)">
            <nb-icon icon="chevron-right" pack="mo-icons" style="transform: rotate(180deg);"></nb-icon>
          </button>
        </div>

        <div class="select-list">
          <h5>ADDED SERVICE CENTRE(S)</h5>
          <div class="select-box">
            <div class="select-box-header">
              <mat-checkbox [checked]="isTargetCheckAll" (click)="handleSelectTransferList($event, null, false, true)">
                {{ targetSelectedListSearched.length }}/{{ targetListSearched.length }} item{{ targetListSearched.length
                > 1 ? 's' : '' }}
              </mat-checkbox>
            </div>
            <div class="select-box-body">
              <label for="search-input" class="list-search">
                <input type="text" id="search-input" nbInput fullWidth class="search-input" placeholder="Search"
                  formControlName="targetSearchValue" />
                <label for="search-input" class="search-icon">
                  <nb-icon icon="search" pack="mo-icons"></nb-icon>
                </label>
              </label>

              <div class="check-list">
                <mat-checkbox *ngFor="let item of targetListSearched"
                  [checked]="isSelectCheck(item, targetSelectedList)"
                  (click)="handleSelectTransferList($event, item, false)">
                  {{ item.name }}
                </mat-checkbox>

                <div class="no-data" *ngIf="targetList.length === 0">
                  <nb-icon icon="empty-box" pack="mo-icons"></nb-icon>
                  <span>No data</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>

    <nb-card-footer class="d-flex flex-row-reverse">
      <button nbButton class="small ml-2" type="submit"
        [disabled]="!(companyList.length > 0) || !(targetList.length > 0)">Save</button>
      <button nbButton class="secondary small mx-2" (click)="close()">Cancel</button>
    </nb-card-footer>
  </nb-card>
</form>