import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MoCalendarComponent } from '../../../../@theme/components/mo-calendar/mo-calendar.component'
import { BookingCalendarQuery, BookingCalendarService } from '../../../../@core/akita-stores/stores/booking-calendar/booking-calendar.store';

import { getTimezone } from '../../../../@core/akita-stores/storage';
import * as moment from 'moment-timezone';

const timezone = getTimezone();
moment.tz.setDefault(timezone);

@Component({
  selector: 'booking-calendar',
  templateUrl: './booking-calendar.component.html',
  styleUrls: ['./booking-calendar.component.scss']
})
export class BookingCalendarComponent implements OnInit {

  moCalendarComponent = MoCalendarComponent;
  selectedDate = new Date();
  startAt = new Date();
  minDate = new Date(2001, 0, 1);

  @Input() type = 'manage-booking';
  @Output() calendarChange: EventEmitter<any> = new EventEmitter();

  bookingCalendar$;

  constructor(
    public bookingCalendarQuery: BookingCalendarQuery,
    public bookingCalendarService: BookingCalendarService,
  ) { }

  ngOnInit() {
    if (this.type == 'manage-booking') {
      if (this.bookingCalendar$) this.bookingCalendar$.unsubscribe();
      this.bookingCalendar$ = this.bookingCalendarQuery.bookingCalendar$.subscribe((data) => {
        this.selectedDate = moment(data.viewDate).toDate();
        this.startAt = moment(data.viewDate).toDate();
        
        if(data.viewDateRange && data.viewDateRange.length > 1) {
          const isRangeSameDay = moment(data.viewDateRange[0]).isSame(data.viewDateRange[1], 'day');

          if (isRangeSameDay) {
            this.selectedDate = moment(data.viewDateRange[0])
            this.startAt = moment(data.viewDateRange[0])
          } else {
            this.selectedDate = null;
            this.startAt = moment(data.viewDateRange[1])
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    this.onSelect(this.selectedDate);
  }

  onSelect(event) {
    if(!event) return;
    this.selectedDate = event;
    this.calendarChange.emit(event);
    if (document.querySelectorAll('.box-calendar-sidebar-booking .mo-calendar__header-label').length > 0) {
      document.querySelectorAll('.box-calendar-sidebar-booking .mo-calendar__header-label')[0].innerHTML = moment(this.selectedDate).format('DD MMMM YYYY');
    }
  }

  ngOnDestroy() {
    if (this.bookingCalendar$) this.bookingCalendar$.unsubscribe();
  }

}
