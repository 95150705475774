export const BOOKING_PERMISSIONS = {
  updateBooking: 'updateBooking',
  adminCreateLogBookServiceBooking: 'adminCreateLogBookServiceBooking',
  adminCreateExpressServiceBooking: 'adminCreateExpressServiceBooking',
  updateBookingToPickup: 'updateBookingToPickup',
  updateBookingToPaylater: 'updateBookingToPaylater',
  updateBookingStatusCheckedIn: 'updateBookingStatusCheckedIn',
  updateBookingStatusCancelled: 'updateBookingStatusCancelled',
  getUserActivityLogsBooking: 'getUserActivityLogsBooking',
  getBookingStatusHistories: 'getBookingStatusHistories',
  getBookingPaymentStatuses: 'getBookingPaymentStatuses',
  getBooking: 'getBooking',
  getBookingsSummaries: 'getBookingsSummaries',
  getBookings: 'getBookings',
  addBookingExtraOption: 'addBookingExtraOption',
  addBookingCustomJob: 'addBookingCustomJob',
  deleteBookingCustomJob: 'deleteBookingCustomJob',
  getAdminBookingTimeSlots: 'getAdminBookingTimeSlots',
  getAvailableBeacons: 'getAvailableBeacons',
  getBookingActivityLogs: 'getBookingActivityLogs',
  getBays: 'getBays',
  removeBookingExtraOption: 'removeBookingExtraOption',
  updateBookingCustomJob: 'updateBookingCustomJob',
  manageBookings: 'manageBookings'
}