import { Component, OnInit, Input } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BookingDetailsSelectedQuery } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.query';
import * as moment from 'moment-timezone';
import { BookingDetailsSelectedService } from '../../../@core/akita-stores/stores/booking-details-selected/booking-details-selected.service';
import { tap } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { getTimezone } from '../../../@core/akita-stores/storage';
import * as _ from 'lodash';

@Component({
  selector: 'activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit {
  bookingActivities;
  statusRefundedBookingActivities;
  statusCompletedBookingActivities;
  arrStatus = [];
  bookingId;
  bookingStatus = [];
  activityLog$;
  bookingDetails;
  userId;
  customerName;
  bookingPaymentId;
  timezone = getTimezone();
  @Input() types: string;
  constructor(
    private bookingsSelectedQuery: BookingDetailsSelectedQuery,
    private bookingsService: BookingDetailsSelectedService,
    private route: ActivatedRoute,
    private toastrService: NbToastrService,
  ) {
    this.bookingId = this.route.snapshot.params.bookingId;
  }

  ngOnInit() {
    this.getBookingDetails();
    this.activityLog();

  }

 activityLog() {
  if (this.types === 'booking') {
    this.activityLog$ = this.bookingsService.getStatusList().pipe(tap((res: any) => {
      this.bookingStatus = res.results;
      this.bookingsService.getBookingActivities(this.bookingId).subscribe((val: any) => {
        this.bookingActivities = val.results.map(item => {
          return {
            icon: item.tags && item.tags.includes('create') ? 'file-plus' : item.tags.includes('update') && item.tags.includes('appointment') ? 'calendar' : 'file',
            name: item.createdBy ? item.createdBy === this.userId ? this.customerName : 'Admin' : null,
            time: item.tags && item.tags.includes('create') ? moment.utc(item.createdAt).tz(this.timezone).format('DD/MM/YYYY - hh:mm A') : item.tags.includes('update') ?
              moment.utc(item.updatedAt).tz(this.timezone).format('DD/MM/YYYY - hh:mm A') : null,
            tags: item.tags ? item.tags : null,
            event: item.tags && item.tags.includes('booking') && item.tags.includes('bookingpayment') && item.tags.includes('create') ? 'Booking payment':
              item.tags && item.tags.includes('booking') && item.tags.includes('bookingpayment') && item.tags.includes('create') ? 'Booking payment':
              item.tags && item.tags.includes('create') ? 'Booking Created' :
              item.tags.includes('update') && item.tags.includes('status') ? 'Status has been changed from' :
              item.tags.includes('update') && item.tags.includes('appointment') ? 'Appointment change to' : null,
            oldBookingStatus: item.data && item.data.oldBookingStatusId ? this.getStatusCode(item.data.oldBookingStatusId, this.bookingStatus) : null,
            newBookingStatus: item.data && item.data.newBookingStatusId ? this.getStatusCode(item.data.newBookingStatusId, this.bookingStatus) : null,
            attention: item.tags && item.tags.includes('update') && item.tags.includes('appointment') ? moment(item.data.newBookedAt).format('DD/MM/YYYY - hh:mm A') : null,
            total: item,
            status: item.data && item.data.status ? item.data.status : ''
          }
        });
        this.statusRefundedBookingActivities = val.results.find(element => element.data.status == "refunded");
        this.statusCompletedBookingActivities = val.results.find(element => element.data.status == "completed");
        if (this.statusRefundedBookingActivities) {
          this.arrStatus.push(this.statusRefundedBookingActivities)
        }
        if (this.statusCompletedBookingActivities) {
          this.arrStatus.push(this.statusCompletedBookingActivities)
        }
      });
    })).subscribe();
  }
 }

  getStatusCode(statusId: number, statusList: any) {
    let statusCode;
    for (let i = 0; i < statusList.length; i++) {
      if (statusId === statusList[i].id) {
        statusCode = statusList[i].code.split('-').join(' ');
      }
    }
    return statusCode;
  };

  getBookingDetails() {
    this.bookingsService.getBookingDetails(this.bookingId).subscribe(() =>{
      this.bookingDetails = this.bookingsSelectedQuery.getValue();
      this.bookingPaymentId = _.get(this.bookingDetails, 'bookingPayments[0].id');
      this.userId = this.bookingDetails.userId;
      this.customerName = this.bookingDetails.customer.firstName + ' ' + this.bookingDetails.customer.lastName;
    })
  };
  deleteLogActivities() {
    this.bookingsService.deleteBookingActivities(this.bookingId, this.bookingPaymentId).subscribe(() =>{
        this.toastrService.success("Booking refunded successfully");
        this.activityLog()
    })
  }

  ngOnDestroy() {
    if (this.activityLog$) this.activityLog$.unsubscribe();
  }
}
