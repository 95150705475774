import { Pipe, PipeTransform } from '@angular/core';
import { VehicleBookingDetails } from '../../@core/data/common';

@Pipe({ name: 'vehicleStr' })
export class VehicleStrPipe implements PipeTransform {
  transform(vehicle: VehicleBookingDetails, type?: string): string {
    if (!vehicle) return ''; 
    let strInfoVehicle = '';
    if (!type || type === 'name') {
      if (vehicle.make) strInfoVehicle += vehicle.make.toUpperCase();
      if (vehicle.model) strInfoVehicle += ' ' + vehicle.model;
      if (vehicle.year) strInfoVehicle += ' ' + vehicle.year;
    }
    if (!type || type === 'colour-cylinder') {
      if (vehicle.colour) strInfoVehicle += ' · ' + vehicle.colour;
      if (vehicle.cylinder) strInfoVehicle += ' · ' + vehicle.cylinder + ' Cylinders'
    }
    const strFirstThree = strInfoVehicle.substring(0,3);
    if (strFirstThree === ' · ') return strInfoVehicle.slice(3);
    return strInfoVehicle.trim();
  }
}
