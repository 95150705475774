import { Injectable } from '@angular/core';
import { StoreConfig, Store } from '@datorama/akita';

export interface TimeSlot {
  slot: string;
  isAvailable: boolean;
}
export interface PartDetails {
  relatedId: number,
  id: number,
  description: string,
  sku: string,
  partNumber: string,
  partType: string,
  qty: number,
  unitPrice: number,
  price: number,
  priceDiscount: number,
  currency: string,
  unit: string
}
export interface JobDetails {
  id: number,
  name: string,
  description: string,
  code: string,
  serviceNote: string,
  price: number,
  isFixedPrice: boolean,
  priceDiscount: number,
  npPrice: number,
  npPriceDiscount: number,
  adjustedPrice: number,
  adjustedPriceDiscount: number,
  duration: number,
  km: number,
  serviceType: {
    id: number,
    description: string,
    code: string
  },
  relatedId: number,
  parts: PartDetails[],
  provider: string,
  npQuoteId: string,
  qualifiedAtUtc: any,
  qualifiedBy: string,
  paymentId: number
}

export interface ExtraOptionDetails {
  id: number,
  code: string,
  name: string,
  description: string,
  priceExclTax: number,
  priceDiscount: number,
  sortOrder: number,
  isBeverage: true,
  relatedId: number,
  npQuoteId: string,
  qualifiedAtUtc: any,
  qualifiedBy: string,
  parts: PartDetails[],
  serviceTime: number
}

export interface BeaconDetails {
  id: number,
  code: string,
  description: string,
  deviceId: string,
  isActive: boolean
}

export interface BookingPaymentDetails {
  id: number,
  reference: string,
  type: string,
  amt: number,
  amtExclDiscount: number,
  paymentFee?: number,
  paymentFeeAmt: number,
  paymentFeeAmtExclDiscount: number,
  amtExclPaymentFee: number,
  subtotal: number,
  subtotalExclDiscount: number,
  gst: number,
  gstExclDiscount: number,
  saved: number,
  status: string,
  statusDetails?: string,
  createdAt: string,
  coupon?: CouponDetails
}

export interface CouponDetails {
  id: number,
  code: string,
  name: string,
  description: string,
  discountPercent: number,
  isExpired: boolean
}

export interface BookingDetails {
  id: number,
  bookedAt: any,
  bookedAtUtc: any,
  userId: any,
  serviceCenter: {
    openingHours: [
      {
        date: any,
        open: string,
        close: string
      }
    ],
    id: number,
    name: string,
    entityId: number,
    contacts: [
      {
        id: number,
        contact: {
          id: number,
          type: string,
          value: string
        },
        isPreferred: true,
        isVerified: true
      }
    ],
    addresses: [
      {
        id: number,
        address: {
          id: number,
          type: string,
          streetLine1: string,
          streetLine2: string,
          city: string,
          state: string,
          postCode: string,
          suburb: string,
          country: string,
          longitude: number,
          latitude: number,
          others: {
            timezone: string
          }
        }
      }
    ]
  },
  customerContact: {
    id: number,
    contact: {
      id: number,
      type: string,
      value: string
    },
    isPreferred: true,
    isVerified: true
  },
  bookingStatus: {
    id?: number,
    description: string,
    code: string
  },
  serviceType: {
    id: number,
    description: string,
    code: string
  },
  bay: {
    id: number,
    bayNo: string,
    description: string,
    pod: {
      id: number,
      name: string
    }
  },
  pod: {
    id: number,
    name: string
  },
  customer: {
    id: number,
    firstName: string,
    lastName: string,
    title: string,
    gender: string,
    dob: any,
    contacts: [
      {
        id: number,
        contact: {
          id: number,
          type: string,
          value: string
        },
        isPreferred: true,
        isVerified: true
      }
    ],
    addresses: [
      {
        id: number,
        address: {
          id: number,
          type: string,
          streetLine1: string,
          streetLine2: string,
          city: string,
          state: string,
          postCode: string,
          suburb: string,
          country: string,
          longitude: number,
          latitude: number,
          others: {
            timezone: string
          }
        }
      }
    ]
  },
  vehicle: {
    customerId: number,
    id: number,
    year: number,
    month: number,
    make: string,
    model: string,
    variant: string,
    description: string,
    colour: string,
    rego: string,
    cylinder: number,
    odometer: number
  },
  serviceTimeInMinutes: number,
  jobs: JobDetails[],
  extraOptions: ExtraOptionDetails[],
  bookingPayments: BookingPaymentDetails[],
  customerNote: string,
  amount: number,
  repairOrder?: {
    id: number,
    customerSignature: string
  },
  bookingCheckin?: any,
  beacon?: BeaconDetails,
  beaconUpdatedAtUtc?: string,
  timeSlots?: TimeSlot[],
  quoteRequestKey: string,
  npVehicleReference: string,
  appointmentRef?: string,
  isEnquiry?: boolean,
}

export function createInitialBookingDetailsSelected(): BookingDetails {
  return {
    "id": 0,
    "bookedAt": "",
    "bookedAtUtc": "",
    "userId": "",
    "serviceTimeInMinutes": 0,
    "serviceCenter": {
      "openingHours": [
        {
          "date": "",
          "open": "",
          "close": ""
        }
      ],
      "id": 0,
      "name": "",
      "entityId": 0,
      "contacts": [
        {
          "id": 0,
          "contact": {
            "id": 0,
            "type": "",
            "value": ""
          },
          "isPreferred": true,
          "isVerified": true
        }
      ],
      "addresses": [
        {
          "id": 0,
          "address": {
            "id": 0,
            "type": "",
            "streetLine1": "",
            "streetLine2": "",
            "city": "",
            "state": "",
            "postCode": "",
            "suburb": "",
            "country": "",
            "longitude": 0,
            "latitude": 0,
            "others": {
              "timezone": ""
            }
          }
        }
      ]
    },
    "customerContact": {
      "id": 0,
      "contact": {
        "id": 0,
        "type": "",
        "value": ""
      },
      "isPreferred": true,
      "isVerified": true
    },
    "bookingStatus": {
      "id": 0,
      "description": "",
      "code": ""
    },
    "serviceType": {
      "id": 0,
      "description": "",
      "code": ""
    },
    "bay": {
      "id": 0,
      "bayNo": "",
      "description": "",
      "pod": {
        "id": 0,
        "name": ""
      }
    },
    "pod": {
      "id": 0,
      "name": ""
    },
    "customer": {
      "id": 0,
      "firstName": "",
      "lastName": "",
      "title": "",
      "gender": "",
      "dob": "",
      "contacts": [
        {
          "id": 0,
          "contact": {
            "id": 0,
            "type": "",
            "value": ""
          },
          "isPreferred": true,
          "isVerified": true
        }
      ],
      "addresses": [
        {
          "id": 0,
          "address": {
            "id": 0,
            "type": "",
            "streetLine1": "",
            "streetLine2": "",
            "city": "",
            "state": "",
            "postCode": "",
            "suburb": "",
            "country": "",
            "longitude": 0,
            "latitude": 0,
            "others": {
              "timezone": ""
            }
          }
        }
      ]
    },
    "vehicle": {
      "customerId": 0,
      "id": 0,
      "year": 0,
      "month": 0,
      "make": "",
      "model": "",
      "variant": "",
      "description": "",
      "colour": "",
      "rego": "",
      "cylinder": 0,
      "odometer": 0
    },
    "jobs": [
      {
        "id": 0,
        "name": "",
        "description": "",
        "code": "",
        "serviceNote": "",
        "price": 0,
        "isFixedPrice": false,
        "priceDiscount": 0,
        "npPrice": 0,
        "npPriceDiscount": 0,
        "adjustedPrice": 0,
        "adjustedPriceDiscount": 0,
        "duration": 0,
        "km": 0,
        "serviceType": {
          "id": 0,
          "description": "",
          "code": ""
        },
        "relatedId": 0,
        "parts": [
          {
            "relatedId": 0,
            "id": 0,
            "description": "",
            "sku": "",
            "partNumber": "",
            "partType": "",
            "qty": 0,
            "unitPrice": 0,
            "price": 0,
            "priceDiscount": 0,
            "currency": "",
            "unit": ""
          }
        ],
        "provider": "",
        "npQuoteId": "",
        "qualifiedAtUtc": "2020-04-07T05:50:17.347Z",
        "qualifiedBy": "",
        "paymentId": 0
      }
    ],
    "extraOptions": [
      {
        "id": 0,
        "code": "",
        "name": "",
        "description": "",
        "priceExclTax": 0,
        "priceDiscount": 0,
        "serviceTime": 0,
        "sortOrder": 0,
        "isBeverage": true,
        "relatedId": 0,
        "npQuoteId": "",
        "qualifiedAtUtc": "2020-04-07T05:50:17.347Z",
        "qualifiedBy": "",
        "parts": [
          {
            "relatedId": 0,
            "id": 0,
            "description": "",
            "sku": "",
            "partNumber": "",
            "partType": "",
            "qty": 0,
            "unitPrice": 0,
            "price": 0,
            "priceDiscount": 0,
            "currency": "",
            "unit": ""
          }
        ]
      }
    ],
    "bookingPayments": [
      {
        "id": 0,
        "type": "",
        "amt": 0,
        "status": "",
        "statusDetails": "",
        "reference": "",
        "amtExclDiscount": 0,
        "paymentFeeAmt": 0,
        "paymentFeeAmtExclDiscount": 0,
        "amtExclPaymentFee": 0,
        "subtotal": 0,
        "subtotalExclDiscount": 0,
        "gst": 0,
        "gstExclDiscount": 0,
        "saved": 0,
        "createdAt": "",
        "coupon": {
          "id": 0,
          "code": "",
          "name": "",
          "description": "",
          "discountPercent": 0,
          "isExpired": true
        }
      }
    ],
    "customerNote": "",
    "amount": 0,
    "repairOrder": {
      "id": 0,
      "customerSignature": ""
    },
    timeSlots: [],
    "quoteRequestKey": "",
    "npVehicleReference": ""
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bookingDetailsSelected' })
export class BookingDetailsSelectedStore extends Store<BookingDetails> {
  constructor() {
    super(createInitialBookingDetailsSelected());
  }

  updateStore(data) {
    this.update(data);
  }

  resetRepairOrder() {
    this.update({
      repairOrder: null
    })
  }

  initNewBooking(datetime: any, serviceType, bay) {
    this.reset();
    this.update({
      bookedAt: datetime,
      serviceType: serviceType,
      bay: bay
    });
  }
}