<ng-container *ngIf="bookingDetails">
  <nb-card class="booking-details-dialog">
    <nb-card-header>Booking #{{bookingDetails.id}}<nb-icon icon="x-icon" pack="mo-icons" (click)="close()"></nb-icon>
    </nb-card-header>
    <nb-card-body class="py-0">
      <div class="row">
        <div class="col-8">
          <div class="sub-content pt-2">
            <h6 class="mb-3 title">VEHICLE</h6>
            <div class="info p-3">
              <h6 class="mb-0 text-uppercase"><span>{{bookingDetails.vehicle.rego }}</span> ·
                {{bookingDetails.vehicle.make}}
                {{bookingDetails.vehicle.model}} {{bookingDetails.vehicle.year}}</h6>
              <p class="mb-0">{{bookingDetails.vehicle.cylinder}} Cylinders <span
                  *ngIf="bookingDetails.vehicle.cylinder && bookingDetails.vehicle.variant"> · </span>
                {{bookingDetails.vehicle.variant}}</p>
            </div>
          </div>
          <div class="sub-content top-space">
            <div class="d-flex bd-highlight">
              <h6 class="mb-3 title">CUSTOMER </h6>
              <div class="ml-auto">
                <span class="icon-container">
                  <nb-icon icon="edit" pack="mo-icons"></nb-icon>
                </span>
              </div>
            </div>
            <div class="info p-3">
              <div class="d-flex bd-highlight">
                <h6 class="mr-auto title">{{bookingDetails.customer.firstName | titlecase}}
                  {{bookingDetails.customer.lastName | titlecase}}
                </h6>
                <div class="mt-2">
                  <a href="mailto:{{email && email.contact ? email.contact.value : ''}}" nbTooltip="Send Email" class="icon-container bg-white">
                    <nb-icon class="red-icon" icon="email" pack="mo-icons"></nb-icon>
                  </a>
                </div>
                <div class="ml-2 mt-2">
                  <a href="tel:+{{phoneContact}}" nbTooltip="Phone Contact" class="icon-container bg-white">
                    <nb-icon class="red-icon" icon="phone-call" pack="mo-icons"></nb-icon>
                  </a>
                </div>
              </div>
              <p class="d-flex align-items-bottom body-3 mx-0 my-2">
                <nb-icon class="mr-2" icon="phone" pack="mo-icons"></nb-icon>{{phoneContact}}
              </p>
              <p class="d-flex align-items-bottom body-3 mx-0 my-2">
                <nb-icon class="mr-2" icon="email" pack="mo-icons"></nb-icon>
                {{email && email.contact ? email.contact.value : ''}}
              </p>
              <p class="d-flex align-items-bottom body-3 mx-0 my-2">
                <nb-icon class="mr-2" icon="home" pack="mo-icons"></nb-icon>{{homeLocation}}
              </p>
              <p class="d-flex align-items-bottom body-3 mx-0 my-2">
                <nb-icon class="mr-2" icon="map-pin" pack="mo-icons"></nb-icon>{{address}}
              </p>
            </div>
          </div>
          <div *ngIf="dataReceipt" class="sub-content mt-3 pb-3">
            <div class="d-flex bd-highlight">
              <h6 class="mb-3 pt-3 title">SERVICE DETAILS </h6>
              <div class="ml-auto">
                <p class="d-flex align-items-bottom body-3">
                  <!-- <span class="pt-3 pr-1 small-title">Hunter Inspection</span> &nbsp; -->
                  <!-- <span class="icon-container mt-2">
                    <nb-icon icon="book" class="red-icon" pack="mo-icons"></nb-icon>
                  </span> -->
                </p>
              </div>
            </div>
            <div class="info p-3">
              <div *ngIf="dataReceipt.isPayWithDiscount"
                class="item d-flex flex-row justify-content-between align-items-start my-2">
                <div class="title text-capitalize medium"></div>
                <div class="price-container d-flex flex-nowrap">
                  <div class="title text-capitalize medium mr-2">Total</div>
                  <div class="title text-capitalize medium ml-1 w-price text-right">Prepaid</div>
                </div>
              </div>
              <div *ngFor="let priceItem of dataReceipt.prices"
                class="item d-flex flex-row justify-content-between align-items-center my-2">
                <div class="title text-capitalize medium"><span>{{ priceItem.name }}</span></div>
                <div *ngIf="dataReceipt.isPayWithDiscount"
                  class="price-container d-flex flex-nowrap justify-content-end">
                  <span class="pr-0 text-decoration-line-through mr-2 medium">{{ priceItem.price }}</span>
                  <span class="medium price ml-1 w-price text-right">{{ priceItem.priceDiscount }}</span>
                </div>
                <div *ngIf="!dataReceipt.isPayWithDiscount"
                  class="price-container d-flex flex-nowrap justify-content-end">
                  <span class="medium price ml-1 w-price text-right">{{ priceItem.price }}</span>
                </div>
              </div>

              <div class="line-price"></div>

              <div class="d-flex flex-row justify-content-between align-items-center total pt-2 mb-2">
                <div class="title text-capitalize medium">
                  Sub Total <mo-tag class="d-inline-block" [tag]="moTag"></mo-tag>
                </div>
                <div class="price-container d-flex flex-nowrap justify-content-end text-right">
                  <div *ngIf="dataReceipt.isPayWithDiscount" class="d-flex flex-nowrap justify-content-end">
                    <span
                      class="pr-0 text-decoration-line-through mr-2 medium">{{ dataReceipt.priceTaxs.subTotalDiscount }}</span>
                    <span class="font-weight-bold price ml-1 w-price">{{ dataReceipt.priceTaxs.subTotal }}</span>
                  </div>
                  <div *ngIf="!dataReceipt.isPayWithDiscount" class="d-flex flex-nowrap justify-content-end">
                    <span class="font-weight-bold price ml-1">{{ dataReceipt.priceTaxs.subTotal }}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row justify-content-between align-items-center total my-2">
                <div class="title text-capitalize medium">
                  GST
                </div>
                <div class="price-container d-flex flex-nowrap justify-content-end text-right">
                  <div *ngIf="dataReceipt.isPayWithDiscount" class="d-flex flex-nowrap justify-content-end">
                    <span
                      class="pr-0 text-decoration-line-through mr-2 medium">{{ dataReceipt.priceTaxs.gstDiscount }}</span>
                    <span class="font-weight-bold price ml-1 w-price">{{ dataReceipt.priceTaxs.gst }}</span>
                  </div>
                  <div *ngIf="!dataReceipt.isPayWithDiscount" class="d-flex flex-nowrap justify-content-end">
                    <span class="font-weight-bold price ml-1">{{ dataReceipt.priceTaxs.gst }}</span>
                  </div>
                </div>
              </div>
              <div *ngIf="dataReceipt.isPaymentFee"
                class="d-flex flex-row justify-content-between align-items-center total my-2">
                <div class="title text-capitalize medium">Card Payment Fee</div>
                <div class="price-container d-flex flex-nowrap justify-content-end text-right">
                  <span class="font-weight-bold price ml-1">{{ dataReceipt.paymentFeeAmt }}</span>
                </div>
              </div>

              <div class="line-price"></div>

              <div class="d-flex flex-row justify-content-between align-items-center total my-2">
                <div class="title text-capitalize medium">Total (inc. GST)</div>
                <div class="price-container d-flex flex-nowrap justify-content-end text-right">
                  <div *ngIf="dataReceipt.isPayWithDiscount" class="d-flex flex-nowrap justify-content-end">
                    <span class="pr-0 text-decoration-line-through mr-2 medium">{{ dataReceipt.total }}</span>
                    <span class="font-weight-bold price ml-1 w-price">{{ dataReceipt.totalDiscount }}*</span>
                  </div>
                  <div *ngIf="!dataReceipt.isPayWithDiscount" class="d-flex flex-nowrap justify-content-end">
                    <span class="font-weight-bold price ml-1">{{ dataReceipt.total }}{{ isExpress ? '*' : '' }}</span>
                  </div>
                </div>
              </div>
              <br *ngIf="dataReceipt.isPayWithDiscount" />
              <div *ngIf="dataReceipt.isPayWithDiscount"
                class="d-flex flex-row justify-content-between align-items-center total my-2">
                <div class="title text-capitalize medium">
                  You saved
                </div>
                <div class="price-container d-flex flex-nowrap justify-content-end text-right">
                  <div class="d-flex flex-nowrap justify-content-end">
                    <!-- <span class="pr-0 text-decoration-line-through mr-2 medium">{{ dataReceipt.saved }}</span> -->
                    <span class="font-weight-bold price ml-1 w-price">{{ dataReceipt.priceTaxs.saved }}</span>
                  </div>
                </div>
              </div>
              <receipt-noted [serviceType]="bookingDetails.serviceType.code"></receipt-noted>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="sub-content pt-2">
            <h6 class="mb-3 title">APPOINTMENT</h6>
            <div class="info p-3">
              <p class="d-flex align-items-bottom calendar body-3 mx-0 m-0">
                <nb-icon class="mr-2" icon="calendar" pack="mo-icons"></nb-icon>
                {{bookingDetails.bookedAt | date:'dd LLL yyyy'}}
              </p>
              <p class="d-flex align-items-bottom clock body-3 mx-0 my-2">
                <nb-icon class="mr-2" icon="clock" pack="mo-icons"></nb-icon>
                {{bookingDetails.bookedAt | date:'shortTime'}}
              </p>
              <p class="d-flex align-items-bottom clock body-3 mx-0 my-2">
                <nb-icon class="mr-2" icon="clock" pack="mo-icons"></nb-icon>
                Est: {{getTotalBookingTime()}} Mins
              </p>
              <p *ngIf="bookingDetails.bay" class="d-flex align-items-bottom location mx-0 mb-0">
                <nb-icon class="mr-2" icon="maximize" pack="mo-icons"></nb-icon>
                {{bookingDetails.bay.bayNo}} - {{bookingDetails.serviceType.description}}
              </p>
            </div>
          </div>
          <div class="sub-content top-space"
            [ngClass]="dataServiceProcess && dataServiceProcess.length > 0 ? '' : 'd-none'">
            <h6 class="mb-3 title">SERVICE STATUS</h6>
            <status-progress [bookingId]="bookingId" (results)="handleServiceProcess($event)"></status-progress>
          </div>
          <button (click)="pickUp()" *ngIf="showPickupButton" class="primary small btn-block save-btn" nbButton>Pick up</button>
          <!-- <mo-tag class="picked-up" *ngIf="isPickedUp" [tag]="{types: 'status', code: 'picked-up'}"></mo-tag> -->
          <button (click)="gotoBookingDetails(bookingDetails.id)" fullWidth nbButton class="my-3" status="primary">View
            Booking</button>

        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-container>
